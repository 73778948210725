.route__campaignList {
    width: 100%;
    height: auto;
    color: white;
    font-family: Inter;

    &__dateFilter {
        display: flex;
        flex-direction: row;
        align-items: center;

        &__head {
            margin-right: 10px;
        }
        & > div {
            border-radius: 5px;
            border: 1px solid gray;
            margin: 0px 5px;
            min-width: 50px;
            text-align: center;
            cursor: pointer;
            transition: color 0.3s ease, border-color 0.3s ease;

            & > span {
                display: block;
                margin: 5px 10px;
            }
        }
        &__item--active {
            color: orange;
            border-color: orange !important;
        }
        &__item--active.route__campaignsList__dateFilter__item--blue {
            color: #2c7de3;
            border-color: #2c7de3 !important;
        }
    }

    &__row {
        width: 100%;
        height: auto;

        display: grid;
        grid-template-columns: 1fr repeat(3,150px);
        grid-template-rows: auto;
        column-gap: 20px;

        & > p {
            height: auto;
            width: 100%;

            &:not(:first-child) {
                text-align: center;
            }
        }
    }
    &__item {
        position: relative;
        width: 100%;
        height: auto;
        padding: 20px;
        margin-bottom: 20px;

        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;

        background-color: #282f37;
        border: 1px solid #8080803d;
        border-radius: 5px;

        &__row {
            width: 100%;   
            height: auto;
            
            display: grid;
            grid-template-columns: 1fr repeat(3,150px);
            grid-template-rows: auto;
            place-items: center;
            column-gap: 20px;

            & > p {
                width: 100%;
                height: auto;

                &:not(:first-child) {
                    text-align: center;
                }
            }
            &--inset {
                padding-left: 20px;

                font-family: Inter;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 17px;
                margin-top: 10px;
            }
            &--inset2 {
                color: #7f8993;
                padding-left: 35px;
            }
            &__actions {
                width: 100%;
                height: auto;

                display: grid;
                grid-auto-flow: column;
                grid-template-columns: repeat(auto-fit,20px);
                grid-template-rows: repeat(auto-fit,20px);
                justify-content: flex-end;
                align-items: center;
                column-gap: 10px;
                row-gap: 20px;

                & > p {
                    position: relative;
                    cursor: pointer;

                    &::after {
                        background-color: #2c7de3;
                        bottom: -2px;
                        content: "";
                        height: 2px;
                        left: auto;
                        position: absolute;
                        right: 0;
                        transition: width .3s ease,left .3s ease,right .3s ease;
                        width: 0;

                    }
                    &:hover::after {
                        left: 0;
                        width: 100%;
                    }
                }
            }
        }
    }
    &__websites {
        widows: 100%;
        height: auto;
        margin-top: 20px;

        display: flex;
        flex-direction: column;
        gap: 5px;

        &__top {
            display: flex;
            justify-content: space-between;
            align-items: center;

            &__right {
                align-items: center;
                cursor: pointer;
                display: flex;
                justify-content: center;
                position: relative;

                & > img {
                    margin-right: 15px;
                    width: 30px;
                    height: 30px;
                }
                & > span {
                    font-family: Inter;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 19px;
                }
                &::after {
                    background-color: #2c7de3;
                    bottom: -6px;
                    content: "";
                    height: 2px;
                    left: auto;
                    position: absolute;
                    right: 0;
                    transition: left .3s ease,right .3s ease,width .3s ease;
                    width: 0;
                }
                &:hover::after {
                    left: 0;
                    right: auto;
                    width: 100%;
                }
            }
        }
        &__list {
            border-left: 2px solid gray;
            height: auto;
            margin-left: 5px;
            padding-left: 15px;
            width: calc(100% - 5px);

            & > p {
                align-items: center;
                display: flex;
                justify-content: flex-start;
                margin-top: 15px;

                & > img {
                    cursor: pointer;
                    margin-right: 10px;
                }
                & > span {
                    color: #7f8993;
                    font-family: Inter;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 24px;
                }
            }
        }
    }
    &__selectWebsite {
        width: 100%;
        height: 100%;

        &__wrap {
            width: 100%;
            height: 100%;
            padding: 20px;
            color: white;

            display: grid;
            grid-template-rows: max-content 1fr;
            grid-template-columns: 1fr;
            row-gap: 20px;

            background-color: #282f37;

            & .route__websites__content__landing {
                background-color: #2f363e;
            }
            &__top {
                display: grid;
                grid-template-columns: 1fr max-content;
                grid-template-rows: max-content;
                column-gap: 20px;

                &__btns {
                    align-items: center;
                    display: flex;
                    height: auto;
                    justify-content: space-between;
                    width: auto;

                    & > p {
                        align-items: center;
                        cursor: pointer;
                        display: flex;
                        justify-content: center;
                        position: relative;

                        &::after {
                            background-color: #2c7de3;
                            bottom: -6px;
                            content: "";
                            height: 2px;
                            left: auto;
                            position: absolute;
                            right: 0;
                            transition: left .3s ease,right .3s ease,width .3s ease;
                            width: 0;
                        }
                        &:hover::after {
                            left: 0;
                            right: auto;
                            width: 100%;
                        }
                        &:last-child::after {
                            background-color: #ea3f3f;
                        }
                        & > span {
                            font-family: Inter;
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 19px;
                        }
                        & > img {
                            margin-left: 15px;
                        }
                    }
                }
            }
            &__bottom {
                width: 100%;
                height: auto;

                display: grid;
                grid-template-columns: repeat(auto-fit,352px);
                grid-template-rows: auto 560px;
                column-gap: 30px;
                row-gap: 30px;
            }
        }
    }
}