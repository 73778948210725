.route__adminTools{
    &__single{
        background-color: #2F363E;
        padding: 20px;
        display: grid;
        grid-template-columns: 1fr max-content;
        grid-template-rows: auto;
        align-items: center;
        justify-content: space-around;
        gap: 20px;
        border-radius: 5px;
        margin-bottom: 10px;
        box-shadow: 0 0 4px 2px rgba(0, 0, 0, 0.25);
        &__name{
            color: white;
            font-family: Inter;
            font-size: 20px;
            margin-bottom: 10px;
        }
        &__description{
            color: white;
            font-family: Inter;
            font-size: 16px;
        }
        &>button{
            width: 150px;
            padding: 15px 20px;
            cursor: pointer;
            background-color: #20272F;
            border: none;
            border-radius: 4px;
            color: white;
            font-family: Inter;
        }
    }
    &__yesNo{
        background-color: #2F363E;
        margin: auto;
        width: 500px;
        padding: 30px;
        &>p{
            font-size: 20px;
            font-family: Inter;
            color: white;
        }
        &>div>p{
            font-family: Inter;
            color: white;
        }
    }
    
}