.route__localization {
    width: 100%;
    height: auto;

    display: flex;
    flex-wrap: wrap;
    gap: 20px;

    &__search {
        flex-basis: 100%;
    }
    &__key {
        padding: 10px 20px;
        color: white;
        border: 1px solid white;
        background-color: transparent;
        border-radius: 5px;
        cursor: pointer;

        transition: background-color 0.3s ease;

        &:hover {
            background-color: rgba(128, 128, 128, 0.425);
        }

        &--yellow {
            border-color: orange;
        }
        &__countries {
            display: flex;
            align-items: center;
            gap: 5px;

            &--green {
                color: rgb(124, 253, 124);
            }
            &--yellow {
                color: orange;
            }
        }
    }
}