.route__websites {
    width: 100%;
    height: 100%;

    color: white;

    display: flex;
    flex-direction: column;
    gap: 20px;

    &__keyval {
        display: grid;
        grid-template-columns: max-content 1fr;
        grid-template-rows: auto;

        & > span:first-child {
            color: rgb(63, 124, 234);
        }
    }
    &__content {
        width: 100%;
        height: max-content;
        min-height: 0;
        display: grid;
        grid-template-columns: repeat(auto-fit,352px);
        grid-template-rows: auto;
        column-gap: 30px;
        row-gap: 30px;
        gap: 20px;

        &__landing {
            width: 100%;
            background-color: #282f37;
            border-radius: 5px;
            padding: 20px;
            box-shadow: none;

            display: flex;
            flex-direction: column;
            gap: 10px;
            
            border-radius: 10px;
            cursor: pointer;
            transition: box-shadow 0.3s ease;

            &:hover {
                box-shadow: 0 0 9px 2px rgba(63,124,234,.75);
            }
            &__id{
                color: white;
                font-family: Inter;
                margin-bottom: 10px;
                padding: 10px 0;
                border-bottom: 1px solid #48525c;

                display: flex;
                justify-content: space-between;
                align-items: center;
            }
            &__name{
                color: white;
                font-family: Inter;
                padding: 10px 0;
                margin-bottom: 10px;
                border-bottom: 1px solid #48525c;
                border-top: 1px solid #48525c;

                display: flex;
                align-items: center;
                flex-wrap: wrap;
                
                & img {
                    margin-left: 10px;
                }
            }
            &__img {
                width: 100%;
                height: 250px;
                display: flex;
                align-items: flex-start;
                justify-content: flex-start;
                overflow: hidden;
                
                &>img{
                    width: 100%;
                    height: 100%;
                }
            }
            &__btns{
                display: flex;
                align-items: center;
                justify-content: flex-start;
                gap: 20px;
            }
        }
    }
    &__add {
        background-color: #282f37;
        padding: 20px;
        border-radius: 5px;
        display: flex;
        align-items: start;
        justify-content: left;
        gap: 10px;
        flex-direction: column;
        & > .component__customComponent__dropbox {
            width: 100%;
        }
        & > p {
            color: white;
            font-family: Inter;
        }
        &__err {
            color: red;
        }
        & > input {
            height: 45px;
            min-width: 136px;
            border: 1px solid #5a5b6a;
            box-sizing: border-box;
            border-radius: 4px;
            color: #19191d;
            display: block;
            position: relative;
            cursor: pointer;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0 12px;
            background-color: transparent;
            width: 100%;
            outline: none;
            color: white;
            font-family: Inter;
            padding: 10px;
            margin-bottom: 0px;
            box-shadow:
                rgb(150, 150, 152) 0px 0px 2px,
                rgb(150, 150, 152) 0px 0px 4px;
        }
        &__buttons {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 20px;
            width: 100%;
            & > button:first-child {
                padding: 10px;
                width: 100px;
                background-color: #4040d4;
                border: none;
                border-radius: 5px;
                color: white;
                font-family: Inter;
                cursor: pointer;
                font-weight: 900;
            }
            & > button:last-child {
                padding: 10px;
                width: 100px;
                background-color: red;
                border: none;
                border-radius: 5px;
                color: white;
                font-family: Inter;
                cursor: pointer;
                font-weight: 900;
            }
        }
    }
    &__delete_wrap{
        background-color: #282f37;
        width: 500px;
        height: auto;
        margin: auto;
        padding: 20px;
        border-radius: 5px;
        &__btns {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 20px;
            width: 100%;
            margin-top: 20px;
            & > button:first-child {
                padding: 10px;
                width: 100px;
                background-color: #4040d4;
                border: none;
                border-radius: 5px;
                color: white;
                font-family: Inter;
                cursor: pointer;
                font-weight: 900;
            }
            & > button:last-child {
                padding: 10px;
                width: 100px;
                background-color: red;
                border: none;
                border-radius: 5px;
                color: white;
                font-family: Inter;
                cursor: pointer;
                font-weight: 900;
            }
        }
    }
}


.route__websites__history {
    width: 100%;
    height: 100%;

    &__wrap {
        width: 100%;
        height: 100%;
        padding: 20px;
        color: white;
        display: grid;
        grid-template-rows: max-content 1fr;
        grid-template-columns: 1fr;
        row-gap: 20px;
        background-color: #20272f;

        &__top {
            width: 100%;

            display: flex;
            align-items: center;
            justify-content: space-between;
            
            &__btns {

                & > p {
                    align-items: center;
                    cursor: pointer;
                    display: flex;
                    justify-content: center;
                    position: relative;
    
                    &::after {
                        background-color: #2c7de3;
                        bottom: -6px;
                        content: "";
                        height: 2px;
                        left: auto;
                        position: absolute;
                        right: 0;
                        transition: left .3s ease,right .3s ease,width .3s ease;
                        width: 0;
                    }
                    &:hover::after {
                        left: 0;
                        right: auto;
                        width: 100%;
                    }
                    &:last-child::after {
                        background-color: #ea3f3f;
                    }
                    & > span {
                        font-family: Inter;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 19px;
                    }
                    & > img {
                        margin-left: 15px;
                    }
                }
            }
        }
        &__bottom {
            width: 100%;
            height: auto;
            overflow-y: auto;
        }
    }
    &__div {
        width: auto;
        height: auto;

        display: flex;
        flex-direction: column;
        gap: 5px;

        &__diff {
            background-color: #3e4a58;
            border-radius: 5px;
            font-size: 12px;
            margin-right: 5px;
            padding: 4px 10px;

            & > span:first-child {
                font-weight: bold;
            }
            &--added {
                background-color: #3d643d;
            }
            &--changed {
                background-color: #594e39;
            }
            &--removed {
                background-color: #593939;
            }
        }
    }
}