.route__userComments {
    width: 100%;
    height: auto;
    color: white;
    font-family: Inter;

    &__chat {
        width: 100%;
        height: auto;
        padding: 10px;
        margin-top: 10px;

        background-color: #282f37;
        border: 1px solid #8080803d;
        border-radius: 5px;

        display: grid;
        grid-template-columns: max-content 1fr;
        grid-template-rows: auto;
        gap: 10px;

        &--inset {
            margin-top: 10px;
            box-shadow: -3px 0px 2px 0px rgba(0,0,0,0.75);
        }
        &__img {
            width: max-content;
            height: max-content;
            max-width: 64px;
            max-height: 64px;
            min-width: 0;
            min-height: 0;

            display: flex;
            justify-content: center;
            align-items: center;

            border-radius: 50%;
            overflow: hidden;

            & > img {
                width: auto;
                max-width: 100%;
                height: auto;
                max-height: 100%;
            }
        }
        &__content {
            display: flex;
            flex-direction: column;
            gap: 10px;

            &__top {
                font-size: 24px;
                font-weight: bold;

                display: flex;
                align-items: center;

                & > span {
                    font-size: 16px;
                    font-weight: normal;
                    margin-left: 10px;
                    color: gray;
                }
            }
            &__bottom {
                display: flex;
                flex-direction: column;
                flex-wrap: wrap;

                & > img {
                    margin-top: 10px;
                    width: auto;
                    max-width: 100%;
                    height: auto;
                    max-height: 150px;
                }
                &__icons {
                    display: flex;
                    align-items: center;
                    gap: 20px;
                    margin-top: 10px;

                    & > p {
                        display: flex;
                        align-items: center;
                        gap: 5px;
                    }
                    &__buttons {
                        display: flex;
                        align-items: center;
                        justify-content: flex-start;
                        gap: 20px;
                        margin-left: 10px;
                        margin-top: 0;
                    }
                }
            }
        }
    }
    &__addComment {
        &__img {
            width: 100%;
            height: auto;
            margin-top: 20px;

            display: flex;
            flex-direction: column;
            gap: 10px;

            & > p {
                text-align: center;
            }
            &__url {
                width: 100%;
                min-height: 100px;
                max-height: 200px;
                position: relative;

                display: flex;
                justify-content: center;
                align-items: center;

                border: 1px solid gray;
                cursor: pointer;

                &::after {
                    content: "";
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
    
                    background-color: #00000082;
                    z-index: 2;
                    opacity: 0;
    
                    transition: opacity 0.3s ease;
                }
                &:hover::after {
                    opacity: 1;
                }
                & > img {
                    width: 100%;
                    height: auto;
                    max-height: 100%;
                }
                & > span {
                    position: absolute;
                    top: 5px;
                    right: 5px;
                    width: 24px;
                    height: 24px;
                    background-size: 100% 100%;
                    z-index: 3;

                    filter: brightness(100%);
                    transition: filter 0.3s ease;

                    &:hover {
                        filter: brightness(50%);
                    }
                }
            }
        }
        textarea {
            width: 100%;
            height: 100px;
            padding: 10px;

            background-color: transparent;
            border: 1px solid white;
            color: white;
            outline: none;
        }
    }
    &__group {
        display: grid;
        grid-template-rows: auto;
        grid-template-columns: repeat(2, 1fr);
        column-gap: 20px;
    }
}