.modals__imageSelectModal {
    width: 100%;
    height: 100%;

    display: grid;
    grid-template-rows: max-content 1fr;
    grid-template-columns: 1fr;
    gap: 20px;

    background-color: #282f37;
    color: white;

    &__head {
        width: 100%;
        height: auto;
        background-color: #2f363e;
        padding: 0 20px;
        padding-top: 20px;
        padding-bottom: 20px;
        align-items: center;

        display: grid;
        grid-template-rows: auto;
        grid-template-columns: 1fr max-content max-content;
        gap: 40px;

        & > p {
            height: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            cursor: pointer;
            color: white;

            &::after {
                content: '';
                position: absolute;
                bottom: -6px;
                left: unset;
                right: 0;
                width: 0%;
                height: 2px;
                background-color: #2c7de3;
                transition: left 0.3s ease, right 0.3s ease, width 0.3s ease;
            }
            &:last-child::after {
                background-color: #EA3F3F;
            }
            &:hover::after {
                right: unset;
                left: 0;
                width: 100%;
            }
            & > span {
                font-family: 'Inter';
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 19px;
            }
        }
    }
    &__content {
        width: 100%;
        height: auto;
        padding: 10px 20px;

        display: grid;
        grid-template-columns: repeat(auto-fit, 300px);
        grid-template-rows: 300px;
        grid-auto-rows: 300px;

        &__img {
            align-items: center;
            background: #2f363e;
            border-radius: 10px;
            cursor: pointer;
            display: flex;
            flex-direction: column;
            height: 100%;
            justify-content: space-between;
            padding: 20px 18px;
            position: relative;
            transition: box-shadow .3s ease;
            width: 100%;

            & > img {
                width: 100%;
                height: auto;
                max-height: 200px;
            }
            & > p {
                width: 100%;
                height: auto;
                padding: 5px 0;
                text-align: center;
                border-top: 1px solid #48525c;
                border-bottom: 1px solid #48525c;
            }
            &:hover {
                box-shadow: 0 0 9px 2px rgba(63,124,234,.75);
            }
        }
    }
    &__upload {
        &__image {
            width: 200px;
            height: 200px;
            margin: 0 auto;
            position: relative;

            display: flex;
            justify-content: center;
            align-items: center;

            outline: none;
            border: 1px solid white;

            overflow: hidden;
            cursor: pointer;

            & > img {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }
            & > input[type=file] {
                display: none;
            }
            &::after {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;

                background-color: #00000082;
                z-index: 2;
                opacity: 0;

                transition: opacity 0.3s ease;
            }
            &:hover::after {
                opacity: 1;
            }
        }
    }
}