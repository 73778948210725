.route__websites__editStatus{
    background-color: #2F363E;
    margin: auto;
    width: 500px;
    min-height: 30px;
    padding: 20px;
    border-radius: 5px;
    &>p{
        margin-bottom: 20px;
    }
    &__buttons {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 20px;
        width: 100%;
        margin-top: 20px;
        & > button:first-child {
            padding: 10px;
            width: 100px;
            background-color: #4040d4;
            border: none;
            border-radius: 5px;
            color: white;
            font-family: Inter;
            cursor: pointer;
            font-weight: 900;
        }
        & > button:last-child {
            padding: 10px;
            width: 100px;
            background-color: red;
            border: none;
            border-radius: 5px;
            color: white;
            font-family: Inter;
            cursor: pointer;
            font-weight: 900;
        }
    }
}